<template>
    <LoadingElement v-if="isLoading"></LoadingElement>
    <div class="filterContainer">
        <div class="selector">
            <v-select
                label="Sorting"
                :items="searchFilters"
                v-model="this.sortingFilter"
                variant="outlined"
                @update:modelValue="onSortingFilterChange"
                clearable
            ></v-select>
        </div>
    </div>
</template>

<script>
    import LoadingElement from '@/components/LoadingElement.vue';
    import searchFilters from '@/data/searchFilters.json';

    export default {
        name: 'SearchFilter',
        components: { LoadingElement },
        props: {
            page: String,
        },
        data() {
            return {
                searchFilters: searchFilters.filters,
                sortingFilter: '',
                isLoading: false
            };
        },
        mounted() {
            this.initializeFiltersFromQuery();
        },
        methods: {
            initializeFiltersFromQuery() {
                const query = this.$route.query;
                if (query.sortingFilter && this.searchFilters.includes(query.sortingFilter)) {
                    this.sortingFilter = query.sortingFilter;
                }
            },
            onSortingFilterChange() {
                this.isLoading = true;
                const query = {};

                if (this.sortingFilter) {
                    query.sortingFilter = this.sortingFilter;
                }

                this.$router.push({
                    path: '/' + this.page,
                    query: query,
                }).then(() => {
                    this.$router.go(0);
                });
            }
        }
    };
</script>

<style scoped>
    .selector {
        width: 15%;
    }

    .filterContainer {
        display: flex;
        border-radius: 1em;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
    }

    @media screen and (max-width: 768px) {
        .selector {
            width: 55%;
        }
    }
</style>
