<template>
    <v-card class="btnCard" :to="route">
        <v-img
            :src="imageUrl"
            class="align-center"
            gradient=""
            height="200px"
            cover
            >
            <v-card-title class="btnText"><v-icon :icon="icon" class="mr-1"></v-icon>{{ actionText }}</v-card-title>
        </v-img>
    </v-card>
</template>

<script>
    export default {
        name: 'ImageBtnCard',
        props: {
            imageUrl: String,
            icon: String,
            actionText: String,
            route: String
        },
        data() {
            return {
            };
        },
        mounted() {
            
        },
    }
</script>

<style scoped>

.btnCard{
    border-radius: 1em;
    flex-wrap: wrap;
    cursor: pointer;
}

.btnText{
    font-weight: 700;
    font-size: 1.5em;
}

</style>