<template>
    <div class="addItemDisplay">
      <div class="addItemContainer">
        <v-row class="addItemRow">
          <v-col cols="12" sm="12" >
            <p class="addItemTitle">Add an Item to your collection to keep track</p>
            <v-card class="lastAddedCard mt-5">
              <p class="titleCardItem">Add a console, a game or an accessory</p>
              <v-row class="addButtons">
                <v-col cols="12" sm="12" md="6" lg="4" v-for="btn in btns" :key="btn.actionText">
                  <ImageBtnCard :imageUrl="btn.imageUrl" :icon="btn.icon" :actionText="btn.actionText" :route="btn.route"></ImageBtnCard>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
</template>

<script>
    import ImageBtnCard from './ImageBtnCard.vue';

    export default {
        name: 'AddItemBlock',
        components: { ImageBtnCard },
        data() {
            return {
                btns : [
                    { imageUrl: require('@/assets/items.webp'), icon: 'mdi-nintendo-switch', actionText: 'Add a console', route: '/additems'},
                    { imageUrl: require('@/assets/games.webp'), icon: 'mdi-pac-man', actionText: 'Add a game', route: '/addgames'},
                    { imageUrl: require('@/assets/accessories.webp'), icon: 'mdi-video-input-scart', actionText: 'Add an accessory', route: '/addaccessories'}
                ]
            };
        },
        mounted() {
            
        },
    }
</script>

<style scoped>

.lastAddedCardContainer{
    margin-top: 1em;
}

.lastAddedCard{
    border-radius: 1em;
    padding: 1em;
    flex-wrap: wrap;
}

.titleCardItem{
    font-family: 'Public Sans', sans-serif;
    font-size: 1.2em;
    color: #FFFFFF;
    margin-bottom: 1em;
}

.addButtons{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.addItemDisplay{
    padding: 1.5em;
    border-radius: 1em;
    background-color: #242C36;
}

.addItemTitle{
    font-family: 'Public Sans', sans-serif;
    font-size: 1.5em;
    color: #FFFFFF;
} 

.addItemContainer{
    margin-top: 1em;
}

.addItemRow{
    display: flex;
    justify-content: center;
}

.addItemCover{
    width: 50%;
}

.coverColumn{
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>