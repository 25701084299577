<template>

    <loading-element v-if="isLoading"></loading-element>
  
    <div class="main">
      <div class="actionBar">
        <v-btn @click="goBack" color="ghostPurple" icon="mdi-arrow-left" class="mr-4"></v-btn>
        <h2 v-if="isEditMode">Modify a game from your collection</h2>
        <h2 v-else>Add a game to your collection</h2>
      </div>
  
      <div class="lastAddedDisplay">
        <div class="titleContainer">
          <p v-if="isEditMode" class="lastAddedTitle">Modify your game informations ...</p>
          <p v-else class="lastAddedTitle">About your new game ...</p>
        </div>
        <div class="lastAddedCardContainer">
          <div class="noLastItem">
            <div class="additemFormContainer">
                <div class="inputForm">
                    <v-text-field clearable label="Game Name" v-model="itemName"></v-text-field>
                </div>

                <div class="inputForm">
                    <v-autocomplete
                        v-model="itemSystem"
                        :items="consoles"
                        label="Console"
                    ></v-autocomplete>
                </div>

                <div class="inputForm">
                    <v-select
                        v-model="itemCondition"
                        :items="conditions"
                        label="Game condition"
                    ></v-select>
                </div>

                <div class="inputForm">
                    <v-select
                        v-model="haveBox"
                        :items="yesNoResponses"
                        label="I have a box"
                    ></v-select>
                </div>

                <div class="inputForm">
                    <v-select
                        v-model="haveAllAccessories"
                        :items="yesNoResponses"
                        label="I have all the accessories"
                    ></v-select>
                </div>

                <div class="inputForm">
                  <v-textarea v-model="particularities" label="Particularities" maxlength="250" auto-grow counter no-resize clearable></v-textarea>
                </div>

                <div class="inputForm">
                  <v-text-field clearable label="Item purchase price" type="number" v-model="purchasePrice"></v-text-field>
                </div>

                <div class="inputForm">
                  <v-select
                    v-model="itemTags"
                    :items="tags"
                    chips
                    label="Tags"
                    multiple
                    taggable
                  ></v-select>
                </div>

                <div class="inputForm mb-7" style="display: flex; justify-content: flex-end;">
                  <v-btn @click="addTagDialog = true" variant="elevated" color="ghostPurple" density="comfortable" rounded="pill">Add Tag</v-btn>
                </div>

                <h2 class="mb-4">Show us your game</h2>

                <v-row class="lastAddedColumn">
                  <v-col cols="12" sm="6" md="4">
                    <div class="imageContainer">
                      <div class="drop display-inline align-center" @dragover.prevent @drop="onDrop(1, $event)">
                        <div v-if="!image" class="helper"></div>
                        <label v-if="!image" class="btn mb-10">
                          SELECT OR DROP AN IMAGE
                          <input type="file" name="image1" @change="onChange(1, $event)">
                        </label>
                        <div v-else class="imageContainerDisplay">
                          <img :src="image" class="img"/>
                          <v-btn class="btn" @click="removeFile(1)" variant="elevated" color="error" icon="mdi-delete"></v-btn>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <div class="imageContainer">
                      <div class="drop display-inline align-center" @dragover.prevent @drop="onDrop(2, $event)">
                        <div v-if="!image2" class="helper"></div>
                        <label v-if="!image2" class="btn mb-10">
                          SELECT OR DROP AN IMAGE
                          <input type="file" name="image2" @change="onChange(2, $event)">
                        </label>
                        <div v-else class="imageContainerDisplay">
                          <img :src="image2" class="img"/>
                          <v-btn class="btn" @click="removeFile(2)" variant="elevated" color="error" icon="mdi-delete"></v-btn>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <div class="imageContainer">
                      <div class="drop display-inline align-center" @dragover.prevent @drop="onDrop(3, $event)">
                        <div v-if="!image3" class="helper"></div>
                        <label v-if="!image3" class="btn mb-10">
                          SELECT OR DROP AN IMAGE
                          <input v-if="!image3" type="file" name="image3" @change="onChange(3, $event)">
                        </label>
                        <div v-else class="imageContainerDisplay">
                          <img :src="image3" class="img"/>
                          <v-btn class="btn" @click="removeFile(3)" variant="elevated" color="error" icon="mdi-delete"></v-btn>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <div class="validateContainer mt-10">
                  <v-btn variant="elevated" rounded="pill" color="grey" to="/dashboard-games" v-if="isEditMode" class="mr-7">Cancel</v-btn>
                  <v-btn variant="elevated" rounded="pill" color="ghostPurple" @click="validate">Save</v-btn>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
        <div class="mb-4">
            <v-col cols="auto">
                <v-dialog
                    transition="dialog-top-transition" class="modalDialog" v-model="addTagDialog">
                    <template v-slot:default="{ isActive }">
                    <v-card>
                        <form>
                        <v-toolbar
                            color="ghostPurple"
                            class="text-center"
                            title="Add a new tag"
                        ></v-toolbar>
                        <v-card-text>
                            <v-text-field clearable label="New Tag" type="text" v-model="newTag"></v-text-field>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn
                                variant="text"
                                @click="isActive.value = false"
                            >Annuler</v-btn>
                            <v-btn
                                variant="elevated"
                                color="ghostPurple"
                                @click="addItem"
                            >Add</v-btn>
                        </v-card-actions>
                        </form>
                    </v-card>
                    </template>
                </v-dialog>
            </v-col>
        </div>
    </div>

    <v-snackbar v-model="missingFields" color="error" :timeout="2000" location="bottom">
        Missings item informations, please fill all the fields.
    </v-snackbar>

    <v-snackbar v-model="successAddingItem" color="success" :timeout="2000" location="bottom">
        Adding item to your collection. Redirecting you to your collection.
    </v-snackbar>
  </template>
  
<script>
    import LoadingElement from './LoadingElement.vue';
    import { onMounted, reactive } from "vue";
    import { getAuth, onAuthStateChanged } from "firebase/auth";
    import { getFirestore, collection, query, where, getDocs, addDoc, doc, updateDoc } from "firebase/firestore";
    import { getStorage, ref, uploadString, getDownloadURL} from "firebase/storage";
    import consoleModels from '@/data/consoleModels.json';
    import consoleConditions from '@/data/consoleConditions.json';
    import { fetchItemInfos } from '../assets/js/gameSearchJs'; 
    import responses from '@/data/responses.json';
    import { v4 as uuidv4 } from 'uuid';
    import md5 from 'md5';
    
    let auth;
    const state = reactive({
        isSignedIn: false,
        userMail: ''
    });
    
    export default {
        name: 'AddItemComp',
        components: { LoadingElement },
        data() {
        return {
            state,
            tags: [],
            itemTags: [],
            addTagDialog: false,
            email: '',
            image: '',
            image2: '',
            image3: '',
            userId: '',
            haveBox: 'Yes',
            haveAllAccessories: 'Yes',
            particularities: '',
            missingFields: false,
            successAddingItem: false,
            itemBrand: '',
            itemCondition: '',
            itemName: '',
            itemSystem: '',
            purchasePrice: '',
            consoles: consoleModels.models,
            conditions: consoleConditions.conditions,
            yesNoResponses: responses.yesNoResponses,
            isLoading: true,
            editItemId: '',
            editItem: {},
            isEditMode: false,
        };
        },
        mounted() {
            this.initializeFiltersFromQuery();
            this.fetchUserData();
        },
        methods: {
            async initializeFiltersFromQuery() {
                const query = this.$route.query;
                if (query.userId) {
                    this.userId = query.userId;
                }
                if(query.itemId){
                    this.editItemId = query.itemId;
                }
                this.isEditMode = this.editItemId !== '';
                try {
                    this.editItem = await fetchItemInfos(this.userId, this.editItemId, "Games");
                    if(this.isEditMode && this.editItem){
                        this.itemBrand = this.editItem.Brand;
                        this.itemCondition = this.editItem.State;
                        this.itemName = this.editItem.Name;
                        this.itemSystem = this.editItem.Console;
                        this.purchasePrice = this.editItem.PurchasePrice;
                        this.particularities = this.editItem.Particularities;
                        this.haveBox = this.editItem.OriginalBox ? 'Yes' : 'No';
                        this.haveAllAccessories = this.editItem.AllAccessories ? 'Yes' : 'No';
                        this.itemTags = this.editItem.Tags;
                        this.image = this.editItem.photoURL[0];
                        this.image2 = this.editItem.photoURL[1];
                        this.image3 = this.editItem.photoURL[2];
                    }
                } catch (error) {
                    console.error("Error fetching item: ", error);
                }
            },
            addItem() {
              if (this.newTag === '') return;
              const newTag = this.newTag;
              this.tags.push(newTag);
              this.newTag = '';
              this.itemTags.push(newTag);
              this.addTagDialog = false;
            },
            async validate() {
                const db = getFirestore();

                if (this.image === '' || this.itemName === '' || this.purchasePrice === '') {
                  this.missingFields = true;
                  return;
                }

                this.isLoading = true;

                const timestamp = Date.now(); // Timestamp actuel
                const uniqueId = uuidv4(); // UUID unique

                const item_id = `${timestamp}_${md5(uniqueId)}`;
                const UserId = this.userId;
                const Brand = this.itemBrand ? this.itemBrand : "Unavailable";
                const Console = this.itemSystem;
                const Model = this.itemModel ? this.itemModel : "Unavailable";
                const Name = this.itemName;
                const OriginalBox = this.haveBox === 'Yes';
                const AllAccessories = this.haveAllAccessories === 'Yes';
                const State = this.itemCondition;
                const Particularities = this.particularities;
                const PurchasePrice = this.purchasePrice;
                const serialNumber = this.serialNumber ? this.serialNumber : "Unavailable";
                const Type = "Game";
                const Tags = this.itemTags;
                const AddDate = new Date();
                const photoURL = [];

                const storage = getStorage();
                const storageRef = ref(storage, `Games/${this.userId}`); // Using Item_id to create a unique folder

                const uploadImage = async (image) => {
                  if (image.startsWith('https://firebasestorage.googleapis.com/')) {
                    return image;
                  }

                  const isValidDataURL = (str) => {
                    const regex = /^\s*data:([a-z]+\/[a-z0-9-+.]+)?(;[a-z-]+=[a-z0-9-]+)*;base64,[a-z0-9+/]+={0,2}\s*$/i;
                    return regex.test(str);
                  };

                  if (!isValidDataURL(image)) {
                    console.error("Image is not a valid data URL");
                    return null;
                  }

                  const timestamp = Date.now();
                  const imageRef = ref(storageRef, `${timestamp}_${Name}`);
                  await uploadString(imageRef, image, 'data_url');
                  return await getDownloadURL(imageRef);
                };

                try {
                    if (this.image) photoURL.push(await uploadImage(this.image));
                    if (this.image2) photoURL.push(await uploadImage(this.image2));
                    if (this.image3) photoURL.push(await uploadImage(this.image3));

                    const itemData = {
                        item_id, UserId, Brand, Console, Tags, Model, serialNumber, Name, OriginalBox, AllAccessories, State, Particularities, PurchasePrice, Type, AddDate, photoURL
                    };

                    if (this.isEditMode && this.editItemId) {
                        const itemQuerySnapshot = await getDocs(query(collection(db, `${this.userId}_Games`), where("item_id", "==", this.editItemId)));
                        
                        if (!itemQuerySnapshot.empty) {
                            const itemDocRef = itemQuerySnapshot.docs[0].ref;
                            await updateDoc(itemDocRef, itemData);
                        } else {
                            console.error("No document to update with item_id: ", this.editItemId);
                        }
                    } else {
                        await addDoc(collection(db, `${this.userId}_Games`), itemData);
                    }

                    // Update user tags
                    const userQuerySnapshot = await getDocs(query(collection(db, "User"), where("userId", "==", this.userId)));
                    userQuerySnapshot.forEach(async (userDoc) => {
                        const userDocId = userDoc.id;
                        await updateDoc(doc(db, "User", userDocId), { userTag: this.tags });
                    });

                    setTimeout(() => {
                        this.$router.push('/dashboard-games');
                    }, 2000);
                } catch (error) {
                    console.error(error);
                    this.errorAddingItem = true;
                }
            },
            async fetchUserData() {
                const auth = getAuth();
                const user = auth.currentUser;
        
                if (user) {
                const uid = user.uid;
                this.userId = uid;
                  
                const db = getFirestore();
                const userCollection = collection(db, "User");
        
                const querySnapshot = await getDocs(query(userCollection, where("userId", "==", uid)));
        
                querySnapshot.forEach((doc) => {
                    const userData = doc.data();
                    this.userId = userData.userId;
                    this.nickName = userData.userName;
                    this.tags = userData.userTag;
                    this.isLoading = false;
                });
                } else {
                  console.error('No user connected');
                }
            },
            goBack() {
                window.history.back(); // Simule un clic sur le bouton de retour du navigateur
            },
            
            removeFile(index) {
              if (index === 1) {
                this.image = '';
              } else if (index === 2) {
                this.image2 = '';
              } else if (index === 3) {
                this.image3 = '';
              }
            },

            onDrop(index, event) {
              event.stopPropagation();
              event.preventDefault();
              var files = event.dataTransfer.files;
              this.createFile(files[0], index);
            },

            onChange(index, event) {
              var files = event.target.files;
              this.createFile(files[0], index);
            },

            async convertToWebP(file) {
              return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = function(event) {
                  const img = new Image();
                  img.onload = function() {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
                    const webpDataUrl = canvas.toDataURL('image/webp');
                    resolve(webpDataUrl);
                  };
                  img.onerror = reject;
                  img.src = event.target.result;
                };
                reader.onerror = reject;
                reader.readAsDataURL(file);
              });
            },

            async createFile(file, index) {
              if (!file.type.match('image.*')) {
                alert('Select an image');
                return;
              }

              try {
                const webpDataUrl = await this.convertToWebP(file);
                if (index === 1) {
                  this.image = webpDataUrl;
                  console.log(this.image);
                  console.log(webpDataUrl);
                } else if (index === 2) {
                  this.image2 = webpDataUrl;
                } else if (index === 3) {
                  this.image3 = webpDataUrl;
                }
              } catch (error) {
                console.error('Error converting image to WebP:', error);
              }
            },
      },
      setup() {
          onMounted(() => {
              auth = getAuth();
              onAuthStateChanged(auth, (user) => {
                state.isSignedIn = !!user;
                state.userEmail = user ? user.email : "";
              });
          });
      }
    }
</script>
  
<style scoped>
    @import '../assets/css/addItem.css' ;
</style>