<template>
    <LoadingElement v-if="isLoading" />
    <MoreDetailsModal v-if="wantMoreDetails" :item="item" @close="closeMoreDetails" />

    <MoreDetailsModal v-if="false" :item="item"/>

    <v-card class="itemCard">
        <v-row class="addItemRow">

            <div>
                <div class="coverColumn">
                    <v-img class="ItemsImages" height="240" :src="item.photoURL[0]" cover></v-img>

                    <v-overlay
                        :model-value="isHovering"
                        class="align-center justify-center"
                        scrim="ghostPurple"
                        contained
                        >
                        <v-btn variant="flat" rounded="lg" color="ghostPurple" @click="MoreInfos">See more</v-btn>
                    </v-overlay>
                </div>
            </div>

            <v-col cols="12">
                <div class="cardHeader">
                    <p class="cardTitle" :title="item.Name">
                        <v-icon v-if="isGame" icon="mdi-pac-man" class="mr-1"></v-icon>
                        <v-icon v-else-if="isAccessory" icon="mdi-video-input-scart" class="mr-1"></v-icon>
                        <v-icon v-else icon="mdi-nintendo-game-boy" class="mr-1"></v-icon>
                    {{ item.Name }}
                    </p>
                </div>
                <v-divider class="my-3" thickness="2"></v-divider>
                <div class="productInfos">
                    <span v-if="isItem || isAccessory" class="infoElement"><v-icon icon="mdi-alphabetical" class="mr-1"></v-icon>Brand : {{ item.Brand ? item.Brand : "Unavailable" }}</span>

                    <span v-if="isItem" class="infoElement"><v-icon icon="mdi-gamepad-variant" class="mr-1"></v-icon>Model : {{ item.Model ? item.Model : "Unavailable" }}</span>

                    <span class="infoElement"><v-icon icon="mdi-nintendo-switch" class="mr-1"></v-icon>Console : <span>{{ item.Console }}</span></span>

                    <span class="infoElement"><v-icon icon="mdi-dropbox" class="mr-1"></v-icon>With Box : <span>{{ item.OriginalBox ? 'Yes' : 'No' }}</span></span>

                    <span class="infoElement"><v-icon icon="mdi-image-broken-variant" class="mr-1"></v-icon>Condition : {{ item.State }}</span>

                    <span class="infoElement"><v-icon icon="mdi-currency-usd" class="mr-1"></v-icon>Price : <span>{{ item.PurchasePrice ? item.PurchasePrice : "Unavailable" }} €</span></span>

                    <span class="mt-2"><v-icon icon="mdi-tag-outline" class="mr-1"></v-icon>Tags : 
                        <span v-for="tag in item.Tags" :key="tag" class="mr-1">
                            <v-chip color="white" text-color="white" small>{{ tag }}</v-chip>
                        </span>
                        <span v-if="!(item.Tags) || item.Tags.length === 0">
                            No tags
                        </span>
                    </span>
                </div>
                <v-divider class="my-3" thickness="0"></v-divider>
                <v-card-actions class="flex justify-space-between">
                    <div class="cardActions">
                        <v-btn variant="elevated" class="editBtn" size="small" @click="editFunction">
                        Edit
                        </v-btn>
                        <v-btn variant="elevated" class="deleteBtn" @click="visible(true)" size="small">
                            Delete
                        </v-btn>
                    </div>
                    <!-- <v-btn variant="elevated" class="moreBtn" @click="MoreInfos" size="small">
                        See More
                    </v-btn> -->
                </v-card-actions>
            </v-col>
        </v-row>
    </v-card>

    <div>
        <div class="mb-4">
            <v-col cols="auto">
                <v-dialog
                    transition="dialog-top-transition" class="modalDialog" v-model="showDeleteDialog">
                    <template v-slot:default="{ isActive }">
                        <v-card>
                            <form>
                            <v-toolbar
                                color="red"
                                class="text-center"
                                title="Would you like to delete this item ?"
                            ></v-toolbar>
                            <v-card-text class="modalText">
                                <p>Are you sure you want to delete {{ this.item.Name }} ? This action is irreversible.</p>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                                <v-btn
                                    variant="text"
                                    @click="isActive.value = false"
                                >Cancel</v-btn>
                                <v-btn
                                    variant="elevated"
                                    color="red"
                                    @click="deleteFunction"
                                >Delete</v-btn>
                            </v-card-actions>
                            </form>
                        </v-card>
                    </template>
                </v-dialog>
            </v-col>
        </div>
    </div>
</template>

<script>
    import { deleteItem } from '../assets/js/gameSearchJs'; 
    import LoadingElement from '@/components/LoadingElement.vue';
    import MoreDetailsModal from '@/components/MoreDetailsModal.vue';

    export default {
        name: 'DisplayCard',
        components: { LoadingElement, MoreDetailsModal },
        props: {
            item: Object,
            isItem: Boolean,
            isAccessory: Boolean,
            isGame: Boolean,
            page: String,
            userId: String
        },
        data() {
            return {
                isLoading: false,
                wantMoreDetails: false,
                showDeleteDialog: false,
                area: ""   
            };
        },
        mounted() {
            if(this.isGame){
                this.area = "Games";
            } else if(this.isAccessory){
                this.area = "Accessories";
            } else {
                this.area = "Items";
            }
        },
        methods: {
            visible(val) {
                this.showDeleteDialog = val;
            },
            async deleteFunction() {
                this.isLoading = true;
                
                try {
                    await deleteItem(this.userId, this.item, this.area);
                    await this.$router.push({ path: '/' + this.page });
                    this.$router.go(0);
                } catch (error) {
                    console.error("Error deleting item: ", error);
                } 
            },
            async editFunction() {
                this.isLoading = true;
                try {
                    const query = {};
                    query.userId = this.userId;
                    query.itemId = this.item.item_id;

                    this.$router.push({
                        path: `/add${this.area.toLowerCase()}`,
                        query: query,
                    }).then(() => {
                        this.$router.go(0);
                    });
                } catch (error) {
                    console.error("Error deleting item: ", error);
                }
            },
            MoreInfos() {
                this.wantMoreDetails = true;
            },
            closeMoreDetails() {
                this.wantMoreDetails = false;
            }
        }
    };
</script>

<style scoped>

    .infoElement{
        padding: 0.2em;
        border: 1px solid #FFFFFF;
        border-radius: 0.5em;
        margin-top: 0.2em;
    }

    .productInfos{
        display: flex;
        flex-direction: column;
        border-radius: 1em;
        padding: 0.5em;
        gap: 0.3em;
        background-color: #2b2b2b;
    }

    .deleteBtn{
        background-color: grey;
        transition: ease-in-out 0.5s;   
    }

    .editBtn{
        background-color: rgb(89, 89, 235);
        transition: ease-in-out 0.5s;
    }

    .moreBtn{
        background-color: #865BB2;
        transition: ease-in-out 0.5s;
    }

    .deleteBtn:hover{
        background-color: red;
        border: none;
    }

    .editBtn:hover{
        background-color: rgb(79, 79, 240);
        border: none;
    }

    .moreBtn:hover{
        background-color: #7323c3;
        transition: ease-in-out 0.5s;
    }

    .modalText{
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .modalDialog{
        width: 50%;
    }

    .cardHeaderActions{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5em;
    }

    .cardHeader{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .cardTitle{
        font-family: 'Public Sans', sans-serif;
        font-size: 1.3em;
        color: #FFFFFF;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    .ItemsImages{
        border-radius: 1em; 
    }

    .itemCard{
        border-radius: 1em;
        padding: 1em;
    }

    .addItemRow{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .coverColumn{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.2em 0.2em 0em 0.2em;
    }

    @media screen and (max-width: 768px) {
      .modalDialog{
        width: 100%;
      }

      .cardTitle{
        font-size: 1.1em;
      }
    }
</style>