<template>
    <div class="loader">
        <div class="modalView">
            <v-card class="modalCard">
                <v-row class="cardContainer">
                    <v-col cols="12">
                        <v-col cols="12" lg="6">
                            <v-carousel 
                                class="photoCarousel" 
                                hide-delimiter-background="true"
                                :show-arrows="item.photoURL.length > 1" 
                                :hide-delimiters="item.photoURL.length <= 1">
                                <v-carousel-item v-for="(photo, index) in item.photoURL" :key="index"
                                    :src="item.photoURL[index]"
                                    cover
                                ></v-carousel-item>
                            </v-carousel>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <div class="productInfos">
                                <span v-if="isItem || isAccessory" class="infoElement"><v-icon icon="mdi-alphabetical" class="mr-1"></v-icon>Brand : {{ item.Brand ? item.Brand : "Unavailable" }}</span>

                                <span v-if="isItem" class="infoElement"><v-icon icon="mdi-gamepad-variant" class="mr-1"></v-icon>Model : {{ item.Model ? item.Model : "Unavailable" }}</span>

                                <span class="infoElement"><v-icon icon="mdi-nintendo-switch" class="mr-1"></v-icon>Console : <span>{{ item.Console }}</span></span>

                                <span class="infoElement"><v-icon icon="mdi-dropbox" class="mr-1"></v-icon>With Box : <span>{{ item.OriginalBox ? 'Yes' : 'No' }}</span></span>

                                <span class="infoElement"><v-icon icon="mdi-image-broken-variant" class="mr-1"></v-icon>Condition : {{ item.State }}</span>

                                <span class="infoElement"><v-icon icon="mdi-currency-usd" class="mr-1"></v-icon>Price : <span>{{ item.PurchasePrice ? item.PurchasePrice : "Unavailable" }} €</span></span>

                                <span class="infoElement"><v-icon icon="mdi-coffee-maker-check" class="mr-1"></v-icon>Have all accessories : <span>{{ item.AllAccessories ? "Yes" : "No" }}</span></span>

                                <span class="infoElement"><v-icon icon="mdi-coffee-maker-check" class="mr-1"></v-icon>Particularities : <span>{{ item.Particularities ? item.Particularities : "Not precised" }}</span></span>

                                <span class="infoElement"><v-icon icon="mdi-calendar" class="mr-1"></v-icon>Add date : <span>{{ formatDate(item.AddDate) }}</span></span>
                                
                                <span class="mt-2"><v-icon icon="mdi-tag-outline" class="mr-1"></v-icon>Tags : 
                                    <span v-for="tag in item.Tags" :key="tag" class="mr-1">
                                        <v-chip color="white" text-color="white" small>{{ tag }}</v-chip>
                                    </span>
                                    <span v-if="!(item.Tags) || item.Tags.length === 0">
                                        No tags
                                    </span>
                                </span>
                            </div>

                            <v-card-actions>
                                <v-btn @click="closeModal">Close</v-btn>
                            </v-card-actions>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </div>
    </div>
</template>
  
<script>

export default {
name: "MoreDetailsModal",
    components: {
    },
    props: {
        item : Object,
    },
    data() {
        return {
          
        };
    },
    mounted() {
        console.log("item: ", this.item);
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },

        formatDate(timestamp) {
            if (!timestamp || !timestamp.seconds) {
                return "Not precised";
            }

            const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);

            const options = { 
                year: 'numeric', 
                month: 'long', 
                day: 'numeric', 
                hour: '2-digit', 
                minute: '2-digit' 
            };

            return date.toLocaleDateString('en-US', options);
        }
    }
};
</script>

<style scoped>

    .photoCarousel{
        border-radius: 1em;
    }

    .infoElement{
        padding: 0.2em;
        border: 1px solid #FFFFFF;
        border-radius: 0.5em;
        margin-top: 0.2em;
    }

    .productInfos{
        display: flex;
        flex-direction: column;
        border-radius: 1em;
        padding: 0.5em;
        gap: 0.3em;
        background-color: #2b2b2b;
    }

    .cardContainer{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .modalCard{
        width: 100%;
        height: 100%;
        border-radius: 1em;
    }

    .modalView{
        width: 80%;
        height: 90%;
        background-color: tomato;
        border-radius: 1em;
    }

    .loader{
        width: 100%;
        height: 100%;
        backdrop-filter: blur(7px);
        display: flex;
        justify-content: center;
        align-items: center;
        position : fixed;
        top: 0;
        left: 0;
        z-index: 9999;
    }

</style>