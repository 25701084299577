<template>
    <div class="searchContainer">
        <div class="searchbar">
            <div class="searchInput">
                <v-text-field
                    v-model="search"
                    label="Items, consoles or games"
                    single-line
                    hide-details
                    variant="outlined"
                ></v-text-field>
            </div>
            <div class="spacer"></div>
            <div class="searchButton">
                <v-btn color="ghostPurple" icon @click="searchItem">
                    <v-icon>mdi-magnify</v-icon>
                </v-btn>
            </div>
            <div class="spacer"></div>
            <div class="openFilterBtn">
                <v-btn color="ghostPurple" icon @click="toggleFilters" size="small">
                    <v-icon>mdi-filter-menu</v-icon>
                </v-btn>
            </div>
        </div>
        <v-row class="filtersContainer" v-show="showFilters">
            <v-col cols="12" md="6" lg="3" class="filter">
                <v-row>
                    <v-col cols="6" class="subfilter">
                        <v-text-field
                            v-model="minPrice"
                            label="Price (min)"
                            single-line
                            hide-details
                            append-inner-icon="mdi-currency-eur"
                            variant="outlined"
                            clearable
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="subfilter">
                        <v-text-field
                            v-model="maxPrice"
                            label="Price (max)"
                            single-line
                            hide-details
                            append-inner-icon="mdi-currency-eur"
                            variant="outlined"    
                            clearable
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6" lg="3" class="filter">
                <v-select
                    label="Tags"
                    :items="tags"
                    v-model="selectedTags"
                    chips
                    multiple
                    variant="outlined"
                ></v-select>
            </v-col>
            <v-col cols="12" md="6" lg="3" class="filter">
                <v-autocomplete
                    label="Console"
                    :items="consoles"
                    v-model="selectedConsole"
                    variant="outlined"
                    clearable
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" lg="3" class="filter">
                <v-autocomplete
                    label="Condition"
                    :items="conditions"
                    v-model="selectedCondition"
                    variant="outlined"
                    clearable
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" lg="3" class="filter">
                <v-btn color="orange" @click="clearFilters" size="small" class="clearButton">Clear</v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import consoleConditions from '../data/consoleConditions';
    import consoleModels from '@/data/consoleModels.json';

    export default {
        name: 'FilterBar',
        props: {
            tags: Array,
            page: String,
        },
        data() {
            return {
                search: '',
                minPrice: '',
                maxPrice: '',
                selectedTags: [],
                selectedConsole: '',
                selectedCondition: '',
                consoles: consoleModels.models,
                conditions: consoleConditions.conditions,
                showFilters: false
            };
        },
        mounted() {
            this.initializeFiltersFromQuery();
        },
        methods: {
            initializeFiltersFromQuery() {
                const query = this.$route.query;
                if (query.search) {
                    this.search = query.search;
                }
                if (query.minPrice) {
                    this.minPrice = query.minPrice;
                }
                if (query.maxPrice) {
                    this.maxPrice = query.maxPrice;
                }
                if (query.tags) {
                    this.selectedTags = query.tags.split(',');
                }
                if (query.console) {
                    this.selectedConsole = query.console;
                }
                if (query.conditions) {
                    this.selectedCondition = query.conditions;
                }
                if(this.minPrice || this.maxPrice || this.selectedTags.length > 0 || this.selectedConsole || this.selectedCondition){
                    this.showFilters = true;
                }
            },
            searchItem() {
                if (this.search === '' && this.minPrice === '' && this.maxPrice === '' && this.selectedTags.length === 0 && this.selectedConsole === '' && this.selectedCondition === '') {
                    return;
                }
                const query = {};

                if (this.search) {
                    query.search = this.search;
                }
                if (this.minPrice) {
                    query.minPrice = this.minPrice;
                }
                if (this.maxPrice) {
                    query.maxPrice = this.maxPrice;
                }
                if (this.selectedTags.length > 0) {
                    query.tags = this.selectedTags.join(',');
                }
                if (this.selectedConsole) {
                    query.console = this.selectedConsole;
                }
                if (this.selectedCondition) {
                    query.conditions = this.selectedCondition;
                }

                this.$router.push({
                    path: '/' + this.page,
                    query: query,
                }).then(() => {
                    this.$router.go(0);
                });
            },
            clearFilters(){
                this.search = '';
                this.minPrice = '';
                this.maxPrice = '';
                this.selectedTags = [];
                this.selectedConsole = '';
                this.selectedCondition = '';
                
                this.$router.push({
                    path: '/' + this.page,
                }).then(() => {
                    this.$router.go(0);
                });
            },
            toggleFilters() {
                this.showFilters = !this.showFilters; // Ajouter cette ligne
            }
        }
    };
</script>

<style scoped>
    .subfilter{
        width: 90%;
    }

    .spacer{
        width: 1em;
    }

    .searchInput{
        width: 100%;
    }

    .searchbar{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .filter{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 1em;
    }

    .searchContainer{
        width: 100%;
        margin-bottom: 2em;
        padding: 1em;
        border-radius: 1em;
        background-color: #242C36;
    }

    .filtersContainer{
        margin-top: 1em;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row; 
        transition: ease-in-out 0.5s;
    }
</style>