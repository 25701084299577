<template>

    <loading-element v-if="isLoading"></loading-element>
  
    <div class="main">
      <div class="actionBar">
        <div class="leftAction">
          <h1>Your games</h1>
        </div>
        <div class="rightAction">
          <v-btn color="ghostPurple" icon="mdi-plus" :to="'/addgames'"></v-btn>
        </div>
      </div>

      <FilterBar :tags="tags" page="dashboard-games"></FilterBar>

      <SearchFilter page="dashboard-games"></SearchFilter>
  
      <div class="lastAddedDisplay">
        <p class="lastAddedTitle">Your games - <span class="itemCount">{{ myItems.length }}</span> item(s) found</p>
        <div class="lastAddedCardContainer">
            <div v-if="this.myItems.length > 0">
              <v-row>
                  <v-col cols="12" sm="6" md="4" lg="3" v-for="item in myItems" :key="item.id">
                    <div>
                        <DisplayCard :item="item" :isGame="true" :isAccessory="false" :isItem="false" page="dashboard-games" :userId="this.userId"></DisplayCard>
                    </div>
                  </v-col>
              </v-row>
            </div>
            <div v-else class="noLastItem">
                <p>Your games will be displayed here</p>
            </div>
        </div>
      </div>      
    </div>

    <MoreDetailsModal v-if="moreDetails"></MoreDetailsModal>
</template>
  
<script>
  import LoadingElement from './LoadingElement.vue';
  import FilterBar from '../utils/Filterbar.vue';
  import DisplayCard from '@/utils/DisplayCard.vue';
  import { onMounted, reactive } from "vue";
  import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
  import { fetchUserData, searchEngine } from '../assets/js/gameSearchJs'; 
  import SearchFilter from '../utils/SearchFilter.vue';
  import MoreDetailsModal from './MoreDetailsModal.vue';
  
  let auth;
  const state = reactive({
    isSignedIn: false,
    userMail: ''
  });
  
  export default {
    name: 'MyGameCollectionComp',
    components: { LoadingElement, FilterBar, DisplayCard, SearchFilter, MoreDetailsModal },
    data() {
      return {
        state,
        visible: true,
        nickName: '',
        email: '',
        userId: '',
        isLoading: true,
        moreDetails: false,
        myItems: [],
        tags: [],
        filters: {
          search: '',
          minPrice: '0',
          maxPrice: '10000',
          selectedTags: [],
          selectedConsole: '',
          selectedCondition: '',
          sortingFilter: ''
        }
      };
    },
    mounted() {
      this.initializeFiltersFromQuery();
      this.fetchUserData();
    },
    methods: {
      initializeFiltersFromQuery() {
          const query = this.$route.query;
          if (query.search) {
              this.search = query.search;
              this.filters.search = this.search;
          }
          if (query.minPrice) {
              this.minPrice = query.minPrice;
              this.filters.minPrice = this.minPrice;
          }
          if (query.maxPrice) {
              this.maxPrice = query.maxPrice;
              this.filters.maxPrice = this.maxPrice;
          }
          if (query.tags) {
              this.selectedTags = query.tags.split(',');
              this.filters.selectedTags = this.selectedTags;
          }
          if (query.conditions) {
              this.selectedCondition = query.conditions;
              this.filters.selectedCondition = this.selectedCondition;
          }       
          if (query.console) {
              this.selectedConsole = query.console;
              this.filters.selectedConsole = this.selectedConsole;
          }   
          if (query.sortingFilter) {
              this.sortingFilter = query.sortingFilter;
              this.filters.sortingFilter = this.sortingFilter;
          }
      },
      handleSignout() {
        signOut(auth)
          .then(() => {
            this.$router.push('/login');
          })
          .catch((error) => {
            console.error(error);
          });
      },
      goBack() {
          window.history.back(); // Simule un clic sur le bouton de retour du navigateur
      },
      async fetchUserData() {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
          this.userId = user.uid;

          try {
            const userInfos = await fetchUserData(this.userId);
            this.nickName = userInfos.nickname;
            this.tags = userInfos.tags;
          } catch (error) {
            console.error('Error getting user informations:', error);
          }

          try {
            const games = await searchEngine(this.userId, this.filters, "Games");
            this.myItems = games;
            if(this.myItems.length !== undefined){
              this.isLoading = false;
            }
          } catch (error) {
            console.error('Error getting user games:', error);
          }

        } else {
          console.error('No user connected');
        }
      },
    },
    setup() {
      onMounted(() => {
        auth = getAuth();
        onAuthStateChanged(auth, (user) => {
          state.isSignedIn = !!user;
          state.userEmail = user ? user.email : "";
        });
      });
    }
  }
  </script>
  
<style scoped>
    .itemCount{
      color: #F59200;
    }

    .ItemsImages{
      border-radius: 1em; 
    }

    .ItemsImages:hover{
      transform: scale(1.1);
      transition: transform 0.5s;
    }
    
    .noLastItem{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1em;
      margin-top: 4vh;
    }
    
    .main{
      padding: 4vh 4vh 10vh 4vh;
    }

    .actionBar{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2em;
    }

    .leftAction{
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .rightAction{
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    
    .lastAddedDisplay{
      padding: 1.5em;
      border-radius: 1em;
      background-color: #242C36;
      margin-bottom: 2em;
    }
    
    .lastAddedTitle{
      font-family: 'Public Sans', sans-serif;
      font-size: 1.5em;
      color: #FFFFFF;
    } 
    
    .lastAddedCardContainer{
      margin-top: 1em;
    }

    @media screen and (max-width: 768px) {
      .main{
        padding: 2vh 2vh 5vh 2vh;
      }

      .actionBar{
        margin-bottom: 1em;
      }

      .leftAction h1{
        font-size: 1.5em;
      }

      .lastAddedTitle{
        font-family: 'Public Sans', sans-serif;
        font-size: 1.2em;
      }

      div.lastAddedDisplay{
        padding: 1em 0.5em 0.5em 1em;
      }
    }
</style>