<template>

  <loading-element v-if="isLoading"></loading-element>

  <div class="main">
    <div class="actionBar">
      <h1>Hello <span class="nickname">{{ nickName }}</span></h1>
      <v-btn v-if="state.isSignedIn" @click="handleSignout" color="ghostPurple" icon="mdi-power"></v-btn>
    </div>

    <div class="lastAddedDisplay">
      <p class="lastAddedTitle">Last Added Items</p>
      <div class="lastAddedCardContainer">
        <v-row v-if="this.lastAddedItems.length > 0" class="lastAddedColumn">
          <v-col cols="12" sm="6" lg="4" v-for="item in lastAddedItems.slice(0, 3)" :key="item.id">
            <div>
                <DisplayCard :item="item" :isGame="true" :isAccessory="false" :isItem="false" page="dashboard-games" :userId="this.userId"></DisplayCard>
            </div>
          </v-col>
        </v-row>

        <div v-else class="noLastItem">
          <p>Your last added items will be displayed here</p>
        </div>
      </div>
    </div>

    <AddItemBlock></AddItemBlock>
    
  </div>
</template>

<script>
import LoadingElement from './LoadingElement.vue';
import { onMounted, reactive } from "vue";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import DisplayCard from '@/utils/DisplayCard.vue';
import AddItemBlock from '@/utils/AddItemBlock.vue';

let auth;
const state = reactive({
  isSignedIn: false,
  userMail: ''
});

export default {
  name: 'HelloWorld',
  components: { LoadingElement, DisplayCard, AddItemBlock },
  data() {
    return {
      state,
      nickName: '',
      email: '',
      userId: '',
      isLoading: true,
      lastAddedItems: [],
    };
  },
  mounted() {
    this.fetchUserData();
  },
  methods: {
    handleSignout() {
      signOut(auth)
        .then(() => {
          this.$router.push('/login');
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async fetchUserData() {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const uid = user.uid;

        const db = getFirestore();
        const userCollection = collection(db, "User");
        const itemCollection = collection(db, `${uid}_Items`);

        const querySnapshot = await getDocs(query(userCollection, where("userId", "==", uid)));

        querySnapshot.forEach((doc) => {
          const userData = doc.data();
          this.nickName = userData.userName;
        });

        const itemSnapshot = await getDocs(
            query(itemCollection, where("UserId", "==", uid))
        );

        itemSnapshot.forEach(doc => {
          this.lastAddedItems.push({ id: doc.id, ...doc.data() });
        });
        this.isLoading = false;
      } else {
        console.error('No user connected');
      }
    },
  },
  setup() {
    onMounted(() => {
      auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        state.isSignedIn = !!user;
        state.userEmail = user ? user.email : "";
      });
    });
  }
}
</script>

<style scoped>

.nickname{
  color: #F59200;
}

.noLastItem{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin-top: 4vh;
}

.main{
  padding-top: 4vh;
  padding-left: 4vw;
  padding-right: 4vw;
  padding-bottom: 10vh;
}

.actionBar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
}

.lastAddedDisplay{
  padding: 1.5em;
  border-radius: 1em;
  background-color: #242C36;
  margin-bottom: 2em;
}

.lastAddedTitle{
  font-family: 'Public Sans', sans-serif;
  font-size: 1.5em;
  color: #FFFFFF;
} 

.lastAddedCardContainer{
  margin-top: 1em;
}

.lastAddedColumn{
  display: flex;
  justify-content: center;
}
</style>