import { getFirestore, collection, query, where, getDocs, addDoc, deleteDoc, doc } from "firebase/firestore";
import { deleteObject } from "firebase/storage";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};

initializeApp(firebaseConfig);
//import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";

const db = getFirestore();

export async function fetchUserData(uid) {
    const userCollection = collection(db, 'User');
    const userInfos = {};

    if (uid) {
        const querySnapshot = await getDocs(query(userCollection, where("userId", "==", uid))); 
        querySnapshot.forEach((doc) => {
            const userData = doc.data();
            userInfos.userid = userData.userId;
            userInfos.nickname = userData.userName;
            userInfos.tags = userData.userTag;
            userInfos.email = userData.userEmail;
        });
    }

    return userInfos;
}

export async function searchEngine(userId, filters, area) {
    const itemCollection = collection(db, `${userId}_${area}`);
    let fetchedItems = []; 

    // Récupérez les éléments triés par ordre décroissant de la date d'ajout (addDate)
    const querySnapshot = await getDocs(
        query(itemCollection,  where("UserId", "==", userId))
    );

    querySnapshot.forEach(doc => {
        // Vous pouvez accéder aux données du document via doc.data()
        fetchedItems.push({ id: doc.id, ...doc.data() });
    });

    if (filters.search) {
        fetchedItems = fetchedItems.filter(item => 
            item.Name && item.Name.toLowerCase().includes(filters.search.toLowerCase())
        );
    }

    if (filters.minPrice) {
        fetchedItems = fetchedItems.filter(item => item.PurchasePrice >= parseFloat(filters.minPrice));
    }

    if (filters.maxPrice) {
        fetchedItems = fetchedItems.filter(item => item.PurchasePrice <= parseFloat(filters.maxPrice));
    }

    if (filters.selectedTags && filters.selectedTags.length > 0) {
        fetchedItems = fetchedItems.filter(item => 
            item.Tags && item.Tags.includes && filters.selectedTags.every(tag => item.Tags.includes(tag))
        );
    }

    if (filters.selectedCondition) {
        fetchedItems = fetchedItems.filter(item => item.State === filters.selectedCondition);
    }

    if (filters.selectedConsole) {
        fetchedItems = fetchedItems.filter(item => item.Console === filters.selectedConsole);
    }

    if (filters.sortingFilter) {
        fetchedItems.sort((a, b) => {
            if (filters.sortingFilter === "Price : Low to High") {
                return a.PurchasePrice - b.PurchasePrice;
            }
            if (filters.sortingFilter === "Price : High to Low") {
                return b.PurchasePrice - a.PurchasePrice;
            }
            if (filters.sortingFilter === "Alphabetical : A-Z") {
                return a.Name.localeCompare(b.Name);
            }
            if (filters.sortingFilter === "Newest first") {
                return b.AddDate - a.AddDate;
            }
            if (filters.sortingFilter === "Oldest first") {
                return a.AddDate - b.AddDate;
            }
        });
    } else {
        fetchedItems.sort((a, b) => b.AddDate - a.AddDate);
    }
    

    return fetchedItems;
}

export async function fetchItemInfos(userId, itemId, area) {
    const itemCollection = collection(db, `${userId}_${area}`);
    let fetchedItem = null;

    const querySnapshot = await getDocs(
        query(itemCollection, where("UserId", "==", userId), where("item_id", "==", itemId))
    );

    querySnapshot.forEach(doc => {
        fetchedItem = { id: doc.id, ...doc.data() };
    });

    return fetchedItem;
}

export async function deleteOldPhoto(oldPhotoURL, storageRef) {
    if (oldPhotoURL.startsWith('https://firebasestorage.googleapis.com/')) {
      await deleteObject(storageRef);
      console.log("Ancienne photo supprimée avec succès");
    } else {
      console.error("L'URL de la photo n'est pas valide pour la suppression");
    }
}

export async function exportItems(userId, area) {
    console.log("Exporting items for user", userId);
    const fetchedItems = [];
    const itemCollection = collection(db, `${userId}_${area}`);
    const querySnapshot = await getDocs(
        query(itemCollection,  where("UserId", "==", userId))
    );

    querySnapshot.forEach(doc => {
        // Vous pouvez accéder aux données du document via doc.data()
        fetchedItems.push({ id: doc.id, ...doc.data() });
    });

    //générer un fichier json avec les items récupérés 
    const json = JSON.stringify(fetchedItems);
    const blob = new Blob([json], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    return url;
}

export async function exportItemsToCSV(userId, area) {
    console.log("Exporting items to CSV for user", userId);
    const fetchedItems = [];
    const itemCollection = collection(db, `${userId}_${area}`);
    const querySnapshot = await getDocs(
        query(itemCollection, where("UserId", "==", userId))
    );

    querySnapshot.forEach(doc => {
        fetchedItems.push({ id: doc.id, ...doc.data() });
    });

    // Convertir les items récupérés en CSV
    const csvRows = [];
    const headers = Object.keys(fetchedItems[0]);
    csvRows.push(headers.join(',')); // Ajouter les en-têtes

    for (const item of fetchedItems) {
        const values = headers.map(header => {
            const escaped = ('' + item[header]).replace(/"/g, '\\"');
            return `"${escaped}"`;
        });
        csvRows.push(values.join(','));
    }

    const csv = csvRows.join('\n');
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    return url;
}

export async function deleteItem(userId, item, area) {
    try {
        const itemId = item.id;
        const itemDocRef = doc(db, `${userId}_${area}`, itemId); 
        await deleteDoc(itemDocRef); 
    } catch (error) {
        console.error("Error deleting item: ", error);
    }
}

export async function fetchStats(userId) {
    const stats = {};
    const itemCollection = collection(db, `${userId}_Items`);
    const gameCollection = collection(db, `${userId}_Games`);
    const accessoryCollection = collection(db, `${userId}_Accessories`);
    let fetchedItems = [];
    let fetchedGames = [];
    let fetchedAccessories = []; 

    const itemSnapshot = await getDocs(
        query(itemCollection,  where("UserId", "==", userId))
    );

    itemSnapshot.forEach(doc => {
        fetchedItems.push({ id: doc.id, ...doc.data() });
    });

    const gameSnapshot = await getDocs(
        query(gameCollection,  where("UserId", "==", userId))
    );

    gameSnapshot.forEach(doc => {
        fetchedGames.push({ id: doc.id, ...doc.data() });
    });

    const accessorySnapshot = await getDocs(
        query(accessoryCollection,  where("UserId", "==", userId))
    );

    accessorySnapshot.forEach(doc => {
        fetchedAccessories.push({ id: doc.id, ...doc.data() });
    });

    stats.itemCount = fetchedItems.length;
    stats.gameCount = fetchedGames.length;
    stats.accessoryCount = fetchedAccessories.length;

    return stats;
}   

export async function migrateCollection(userId, items, area) {
    const userAreaCollection = collection(db, `${userId}_${area}`);
    const addItemsPromises = items.map(async (item) => {
        await new Promise((resolve) => {
            setTimeout(async () => {
                try {
                    
                    item.UserId = userId; // Ajout de l'attribut item_id à l'élément
                    await addDoc(userAreaCollection, item); // Ajout de l'élément à la collection
                } catch (error) {
                    console.error("Erreur lors de l'ajout de l'élément à la collection", `${userId}_${area}`, ":", error);
                }
                resolve(); // Résolution de la promesse après l'ajout de l'élément
            }, 2000); // Attendre 2 secondes avant d'ajouter l'élément suivant
        });
    });

    try {
        await Promise.all(addItemsPromises);
    } catch (error) {
        console.error("Erreur lors de l'ajout des éléments à la collection", `${userId}_${area}`, ":", error);
    }
}