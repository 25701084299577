<template>
    <LoadingElement v-if="isLoading" />
    <div class="main">
        <div class="profilHeader">
            <p class="">Hi {{ this.nickName }}</p> 
            <v-btn v-if="state.isSignedIn" @click="handleSignout" color="ghostPurple" icon="mdi-power"></v-btn>
        </div>
        <div class="addItemDisplay">
            <p class="addItemTitle">About your account</p>
            <div class="addItemContainer">
                <v-row class="addItemRow">
                <v-col cols="12" sm="12">
                    <v-card class="lastAddedCard mt-5">
                    <p class="titleCardItem">Add a console, a game or an accessory</p>
                    <v-row class="addButtons">
                        <v-col cols="12" sm="4">
                        <v-card color="ghostPurple" class="btnCard" :to="'/dashboard-items'">
                            <v-card-title><v-icon icon="mdi-nintendo-game-boy" class="mr-1"></v-icon>You have {{ this.itemCount }} items</v-card-title>
                        </v-card>
                        </v-col>
                        <v-col cols="12" sm="4">
                        <v-card color="info" class="btnCard" :to="'/dashboard-games'">
                            <v-card-title><v-icon icon="mdi-pac-man" class="mr-1"></v-icon>You have {{ this.gameCount }} games</v-card-title>
                        </v-card>
                        </v-col>
                        <v-col cols="12" sm="4">
                        <v-card color="grey" class="btnCard" :to="'/dashboard-accessories'">
                            <v-card-title><v-icon icon="mdi-video-input-scart" class="mr-1"></v-icon>You have {{ this.accessoryCount }} accessories</v-card-title>
                        </v-card>
                        </v-col>
                    </v-row>
                    </v-card>
                </v-col>
                </v-row>
            </div>
        </div>

        <div class="exportSettingsContainer">
            <h2 class="mb-5">Export your data</h2>
            <v-row class="exportSettings">
                <v-col cols="12" md="12" lg="6" class="setting">
                    <v-select
                        v-model="area"
                        :items="['Games', 'Items', 'Accessories']"
                        label="Select the area to export"
                        outlined
                        variant="outlined"
                    ></v-select>
                </v-col>
                <v-col cols="12" md="12" lg="5" class="setting">
                    <v-select
                        v-model="selectedFormat"
                        :items="['csv', 'json']"
                        label="Select the format"
                        outlined
                        variant="outlined"
                    ></v-select>
                </v-col>
                <v-col cols="12" md="12" lg="1" class="setting">
                    <v-btn color="orange" @click="exportData" class="clearButton">Export</v-btn>
                </v-col>
            </v-row>
        </div>

    </div>

    <v-snackbar v-model="missingFields" color="error" :timeout="2000" location="bottom">
        Missings export informations, please fill all the requirements.
    </v-snackbar>
</template>

<script>
    import LoadingElement from './LoadingElement.vue';
    import { onMounted, reactive } from "vue";
    import { fetchUserData, fetchStats, exportItemsToCSV, exportItems } from '../assets/js/gameSearchJs'; 
    import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
    
    let auth;
    const state = reactive({
        isSignedIn: false,
        userMail: ''
    });
    
    export default {
        name: 'MyGameCollectionComp',
        components: { LoadingElement },
        data() {
            return {
                state,
                missingFields: false,
                nickName: '',
                email: '',
                userId: '',
                area: '',
                selectedFormat: '',
                itemCount: 0,
                gameCount: 0,
                accessoryCount: 0,
                isLoading: true,
                myItems: [],
                tags: [],
            };
        },
        mounted() {
            this.fetchUserData();
            this.fetchStats();
        },
        methods: {
            async exportData() {
                if(this.area && this.selectedFormat){
                    try {
                        let url;
                        if(this.selectedFormat && this.selectedFormat === 'json'){
                            url = await exportItems(this.userId, this.area);
                        } else {
                            url = await exportItemsToCSV(this.userId, this.area);
                        }
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = `exported_${this.area}.${this.selectedFormat}`;
                        link.click();
                        URL.revokeObjectURL(url);
                    } catch (error) {
                        console.error('Error exporting data:', error);
                    }
                } else {
                    this.missingFields = true;
                }
            },
            async fetchStats() {
                try {
                    const statsInfos = await fetchStats(this.userId);
                    this.itemCount = statsInfos.itemCount;
                    this.gameCount = statsInfos.gameCount;
                    this.accessoryCount = statsInfos.accessoryCount;
                } catch (error) {
                    console.error('Error getting stats informations:', error);
                }
            },
            async fetchUserData() {
                const auth = getAuth();
                const user = auth.currentUser;
        
                if (user) {
                    this.userId = user.uid;

                    try {
                        const userInfos = await fetchUserData(this.userId);
                        this.nickName = userInfos.nickname;
                        this.tags = userInfos.tags;
                        this.email = userInfos.email;
                        this.isLoading = false;
                    } catch (error) {
                        console.error('Error getting user informations:', error);
                    }
                } else {
                    console.error('No user connected');
                }
            },
            handleSignout() {
            signOut(auth)
                .then(() => {
                this.$router.push('/login');
                })
                .catch((error) => {
                console.error(error);
                });
            },
        },
        setup() {
            onMounted(() => {
                auth = getAuth();
                onAuthStateChanged(auth, (user) => {
                state.isSignedIn = !!user;
                state.userEmail = user ? user.email : "";
                });
            });
        }
    }
</script>

<style scoped>

.exportSettingsContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1em;
    background-color: #242C36;
    border-radius: 1em;
    margin-top: 1em;
}

.exportSetting{
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
}

.setting{
    display: flex;
    justify-content: center;
    align-items: center;
}

.profilHeader{
    font-family: 'Public Sans', sans-serif;
    font-size: 2em;
    font-weight: 800;
    color: #FFFFFF;
    margin-bottom: 0.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.main{
  padding-top: 4vh;
  padding-left: 4vw;
  padding-right: 4vw;
  padding-bottom: 10vh;
}

.btnCard{
  border-radius: 1em;
  padding: 1em;
  flex-wrap: wrap;
  cursor: pointer;
}

.titleCardItem{
  font-family: 'Public Sans', sans-serif;
  font-size: 1.2em;
  color: #FFFFFF;
  margin-bottom: 1em;
}

.addButtons{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.lastAddedCard{
  border-radius: 1em;
  padding: 1em;
  flex-wrap: wrap;
}

.lastAddedColumn{
  display: flex;
  justify-content: center;
}

.addItemDisplay{
  padding: 1.5em;
  border-radius: 1em;
  background-color: #242C36;
}

.addItemTitle{
  font-family: 'Public Sans', sans-serif;
  font-size: 1.5em;
  color: #FFFFFF;
} 

.addItemContainer{
  margin-top: 1em;
}

.addItemRow{
  display: flex;
  justify-content: center;
}

.addItemCover{
  width: 50%;
}

.coverColumn{
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>